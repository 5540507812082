<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language list-unstyled"
    right
  >
    <template #button-content>
      <b-img
        :src="solveLangFlag(currentLocale.icon)"
        height="14px"
        width="22px"
        :alt="currentLocale.key"
      />
    </template>
    <b-dropdown-item
      v-for="lang in locales"
      :key="lang.key"
      @click="changeLocale(lang)"
      class="d-flex"
    >
      <b-img
        :src="solveLangFlag(lang.icon)"
        height="14px"
        width="22px"
        :alt="lang.key"
      />
      <span class="ml-50">{{ lang.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import faFlag from "@/assets/images/flags/fa.png";
import enFlag from "@/assets/images/flags/en.png";
import { langInfoDao } from "@/daos/languages/langInfoDao";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      languagesFlag: {
        fa: faFlag,
        en: enFlag,
      },
    locales :[
      {key: 'fa', name: 'فارسی', icon: 'fa'},
      {key: 'en', name: 'English', icon: 'en'},
    ]
    };
  },
  created() {
    this.getLanguagesList();
    this.$i18n.locale = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    if (this.$i18n.locale == "fa") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((lang) => lang.key === this.$i18n.locale);
    },
    solveLangFlag() {
      return (icon) => this.languagesFlag[icon];
    },
  },


  methods: {
    async changeLocale(lang) {
      this.$i18n.locale = lang.key;
      localStorage.setItem("language", lang.key);

      const isRTL = lang.key === "fa";
      document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
      try {
        const response = await this.$http.patch("api/user/language", {
          language_key: lang.key,
        });
      } catch (error) {
        console.error("Error changing language:", error);
      }
    },
    async getLanguagesList() {
      const langInfo= await langInfoDao.getLangInfo();
      this.locales=Object.entries(langInfo).map(([key, value])=>value);
    },
  },

};
</script>

<style></style>
